import React, { Component } from 'react';
import { Container, Col, Row, UncontrolledAlert } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LogInForm } from 'biologis-react-components';
import Footer from '../../components/Footer';
import LanguageSwitcher from '../../components/LangSwitcher';
import CookieConsent from './components/CookieConsent';
import RightSidebar from '../../components/RightSidebar';
import { apiService, stateService } from '../../services';
import { getLanguage } from '../../utils/language';
import settings from '../../utils/settings';

let headerLogos = require('../../images/' + settings.getSetting('site_logo'));

export class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false
    };
  }

  componentDidMount() {
    const lang = this.props.lang || getLanguage();
    this.props.setLangLocally(lang);
  }

  handleLogIn = async (username, password) => {
    try {
      const userAction = await this.props.logIn(username, password);

      if (userAction.ok) {
        const { user } = this.props;
        // set app's language to user's profile language (fallback to current local language)
        const userLang =
          (user.attributes || {}).preferred_langcode || this.props.lang;
        await this.props.setLangLocally(userLang);
        // get customer logo
        const customerAction = await this.props.getCustomer(
          `/dm_customer/dm_customer/${user.relationships.field_customer.data.id}`
        );

        if (
          !customerAction.error &&
          (
            (
              (((customerAction.payload || {}).data || {}).relationships || {})
                .dm_logo || {}
            ).data || {}
          ).id
        ) {
          await this.props.getFile(
            customerAction.payload.data.relationships.dm_logo.data.id
          );
        }
      } else if (this.props.error) {
        this.setState({
          error: true
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { isLoggedIn, user, setLangLocally, isLoggingIn } = this.props;
    const username = new URLSearchParams(this.props.location.search).get(
      'username'
    );

    if (isLoggedIn && user && user.id) {
      return <Redirect to="/" />;
    }

    return (
      <Container fluid className="login text-center">
        <Row>
          <Col
            lg="10"
            className="d-flex flex-column justify-content-between align-items-center"
          >
            <LanguageSwitcher handleLangChange={lang => setLangLocally(lang)} />

            <div className="form-wrapper">
              <img
                alt={settings.getSetting('site_name')}
                className="logo my-2"
                src={headerLogos}
              />
              <FormattedMessage id="logIn.welcome">
                {message => <h2 className="mb-4">{message}</h2>}
              </FormattedMessage>

              {this.state.error && (
                <UncontrolledAlert color="danger">
                  <FormattedMessage id="logIn.loginError" />
                </UncontrolledAlert>
              )}

              <LogInForm
                logIn={this.handleLogIn}
                username={username}
                isLoading={isLoggingIn}
              />

              <Link to="/reset-password" className="btn btn-link my-4">
                <FormattedMessage id="logIn.passwordReset" />
              </Link>
            </div>
            <Footer />
            <CookieConsent />
          </Col>

          <RightSidebar />
        </Row>
      </Container>
    );
  }
}

LogIn.propTypes = {
  logIn: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  error: PropTypes.string,
  userId: PropTypes.string,
  setLangLocally: PropTypes.func,
  location: PropTypes.object,
  lang: PropTypes.string.isRequired,
  getCustomer: PropTypes.func.isRequired,
  getFile: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isLoggingIn: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    error: state.auth.error,
    lang: state.lang.lang,
    user: state.user.data,
    isLoggingIn: state.auth.isFetching
  };
};

export default connect(mapStateToProps, {
  logIn: apiService.logIn,
  setLangLocally: stateService.setLangLocally,
  getCustomer: apiService.getCustomer,
  getFile: apiService.getFile
})(LogIn);
