import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar';
import DrugCkeck from '../DrugCheck';
import Report from '../Report';
import Settings from '../Settings';
import SearchResults from '../SearchResults';
import Information from '../Information';
import Favorites from '../Favorites';
import RightSidebar from '../../components/RightSidebar';
import DataTable from '../../components/DataTable';
import Gene from '../../components/Gene';
import Feedback from '../../components/Feedback';

class Main extends Component {
  render() {
    return (
      <Container fluid className="app">
        <Row>
          <Col lg="2" xs="12" className="sidebar">
            <Route path="/" component={() => <Navbar />} />
          </Col>
          <Col lg={{ size: 8 }} className="main">
            <main className="content">
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/search" />}
                />
                <Route exact path="/search" component={DrugCkeck} />
                <Route exact path="/search/results" component={SearchResults} />
                <Route path="/my-dna/reports" component={Report} />
                <Route
                  exact
                  path="/my-dna/genes"
                  render={props => (
                    <DataTable tableKey="gene_table" {...props} />
                  )}
                />
                <Route
                  path="/my-dna/results"
                  render={props => (
                    <DataTable tableKey="result_table" {...props} />
                  )}
                />
                <Route
                  path="/my-dna/drugs"
                  render={props => (
                    <DataTable tableKey="active_ingredient_table" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/my-dna/genes/:id"
                  component={({ match }) => (
                    <div className="p-4">
                      <Gene id={match.params.id} />
                    </div>
                  )}
                />
                <Route path="/favorites" component={Favorites} />
                <Route
                  path="/info/support_info"
                  render={props => (
                    <Information {...props}>
                      <Feedback />
                    </Information>
                  )}
                />
                <Route path="/info/imprint" component={Information} />
                <Route
                  path="/info/terms_and_conditions"
                  component={Information}
                />
                <Route path="/info/privacy_policy" component={Information} />
                <Route path="/settings" component={Settings} />
                <Route render={() => <Redirect to="/" />} />
              </Switch>
            </main>
          </Col>
          <RightSidebar />
        </Row>
      </Container>
    );
  }
}

export default Main;
