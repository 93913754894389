import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import FavoriteStar from '../FavoriteStar';
import { intlShape, injectIntl } from 'react-intl';

export class Gene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      geneInformation: ''
    };

    this.geneId = props.id;
  }

  componentDidMount() {
    this.getGeneInformation(this.geneId, this.props.lang);
  }

  getGeneInformation = (id, lang) => {
    fetch(
      process.env.PUBLIC_URL +
        `/gene-information/${lang}/${id.toUpperCase()}.html`
    )
      .then(r => r.text())
      .then(text => {
        this.setState({ geneInformation: text });
      });
  };

  render() {
    const { intl } = this.props;
    const { geneInformation } = this.state;

    const geneItem = {
      name: this.geneId,
      type: 'gene',
      value: { value: this.geneId }
    };

    return (
      <Container>
        <Row>
          <Col className="mb-4">
            <FavoriteStar
              favorite={geneItem}
              labelAdd={intl.formatMessage({
                id: 'search.favorite_button_add'
              })}
              labelRemove={intl.formatMessage({
                id: 'search.favorite_button_remove'
              })}
              labelError={intl.formatMessage({
                id: 'favorites.actions.remove.errorMessage'
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col className="gene px-5 pb-5">
            <h1>{this.geneId}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: geneInformation
              }}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

Gene.propTypes = {
  intl: intlShape.isRequired,
  lang: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  favorites: PropTypes.object
};

const mapStateToProps = state => {
  return {
    lang: state.lang.lang,
    favorites: state.favorites.data
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(Gene)));
