import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Autosuggest from 'react-autosuggest';
import { Close, Search } from 'grommet-icons';
import { FormGroup, Input, InputGroup } from 'reactstrap';
import { apiService, stateService } from '../../services';

export class Autocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      label: '',
      type: '',
      suggestionOpen: false
    };

    this.timeout = undefined;
    this.searchInput = null;
  }

  componentDidMount() {
    // get predefined search value from url
    const searchParam =
      new URLSearchParams(this.props.location.search).get('searchParam') || '';
    const searchLabel =
      new URLSearchParams(this.props.location.search).get('searchLabel') || '';
    const searchType =
      new URLSearchParams(this.props.location.search).get('searchType') || '';

    this.setState(
      {
        value: searchParam,
        label: searchLabel,
        type: searchType
      },
      () => {
        if (!this.state.value) {
          // clear search input value and remove search results if no param in URL
          this.props.clearSearchSuggestions();
          this.props.clearSearch();
        } else {
          // trigger search only if search params have changed
          if (
            !this.props.isFetching &&
            (!this.props.searchItem ||
              searchParam !== this.props.searchItem.value ||
              searchLabel !== this.props.searchItem.label ||
              searchType !== this.props.searchItem.type)
          ) {
            // fire search if search param is already prefilled in URL
            this.loadRecommendations({
              value: this.state.value,
              label: this.state.label,
              type: this.state.type
            });
          }
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  setFocus = () => {
    // set the focus on the autosuggest input element after component mounted
    if (this.searchInput) {
      this.searchInput.focus();
    }
  };

  clearSearch = () => {
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: ''
    });
    this.props.clearSearch();
    this.setState({
      value: '',
      label: '',
      type: ''
    });
    this.setFocus();
  };

  onChange = event =>
    this.setState({
      value: event.target.value,
      label: event.target.value,
      type: event.target.value,
      suggestionOpen: true
    });

  onBlur = () =>
    this.setState({
      suggestionOpen: false
    });

  onMouseOut = () => {
    this.timeout = setTimeout(this.onBlur, 1000);
  };

  loadRecommendations = selectedItem =>
    this.props.getRecommendationsBySearchParam(selectedItem, this.props.lang);

  loadAutocomplete = value =>
    this.props.getAutocomplete(value, this.props.lang);

  onSelect = (event, { suggestion }) => {
    if (this.props.onSelect) {
      this.props.onSelect();
    }

    const { label, value, result_type } = suggestion;
    this.setState(
      { value, label, type: result_type, suggestionOpen: false },
      () => {
        this.loadRecommendations({
          value: this.state.value,
          label: this.state.label,
          type: this.state.type
        });
        // update url to match search
        this.props.history.push({
          pathname: '/search/results',
          search:
            '?' +
            new URLSearchParams({
              searchParam: value,
              searchLabel: label,
              searchType: result_type
            }).toString()
        });
      }
    );
  };

  renderSuggestion = suggestion => {
    return (
      <div>
        <div className="font-weight-bold">{suggestion.label}</div>
        <div>
          <FormattedMessage id={`search.${suggestion.result_type}`} />
        </div>
      </div>
    );
  };

  renderInputComponent = inputProps => (
    <InputGroup>
      <Search className="searchIcon" />
      <Input name="drugSearch" {...inputProps} />
      <Close className="clearIcon" onClick={this.clearSearch} />
    </InputGroup>
  );

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const { suggestionOpen } = this.state;
    const { isFetching, autocompleteData } = this.props;

    if (
      !isFetching &&
      suggestionOpen &&
      query.length > 2 &&
      autocompleteData.length <= 0
    ) {
      return (
        <div {...containerProps}>
          <div className="react-autosuggest__suggestion-list--empty">
            <FormattedMessage id="search.autocomplete_no_results" />
          </div>
        </div>
      );
    }
    return <div {...containerProps}>{children}</div>;
  };

  getSuggestionValue = suggestion => {
    return suggestion.label;
  };

  render() {
    const { label } = this.state;
    const { intl, autocompleteData, clearSearchSuggestions } = this.props;

    const inputProps = {
      placeholder: intl.formatMessage({ id: 'search.inputText' }),
      value: label,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onMouseOut: this.onMouseOut,
      autoFocus: true
    };

    return (
      <FormGroup className="autocomplete">
        <Autosuggest
          suggestions={autocompleteData}
          onSuggestionsFetchRequested={this.loadAutocomplete}
          onSuggestionsClearRequested={clearSearchSuggestions}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          renderInputComponent={this.renderInputComponent}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          ref={autosuggest => {
            if (autosuggest !== null) {
              this.searchInput = autosuggest.input;
            }
          }}
          inputProps={inputProps}
          onSuggestionSelected={this.onSelect}
          highlightFirstSuggestion={true}
          focusInputOnSuggestionClick={false}
        />
      </FormGroup>
    );
  }
}

Autocomplete.propTypes = {
  autocompleteData: PropTypes.array.isRequired,
  searchResults: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  lang: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  clearSearchSuggestions: PropTypes.func.isRequired,
  clearSearch: PropTypes.func,
  getAutocomplete: PropTypes.func,
  searchItem: PropTypes.object,
  getRecommendationsBySearchParam: PropTypes.func,
  onSelect: PropTypes.func
};

const mapStateToProps = state => {
  return {
    autocompleteData: state.autocomplete.autocompleteData,
    isFetching: state.autocomplete.isFetching,
    searchResults: state.search.searchResults,
    searchItem: state.search.searchItem,
    lang: state.lang.lang
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      getAutocomplete: apiService.getAutocomplete,
      getRecommendationsBySearchParam:
        apiService.getRecommendationsBySearchParam,
      clearSearchSuggestions: stateService.clearSearchSuggestions,
      clearSearch: stateService.clearSearch
    }
  )(injectIntl(Autocomplete))
);
