import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
// function needed to transform structure of messages to be understood by IntlProvider
import { flattenMessages } from 'client-core';
import { withAuth } from 'biologis-react-components';
import Main from '../Main';
import LogIn from '../LogIn';
import LogOut from '../../components/LogOut';
import Header from './components/Header';
import FirstLoginPasswordChangePrompt from '../FirstLoginPasswordChangePrompt';
import FirstLoginEmailSetPrompt from '../FirstLoginEmailSetPrompt';
import RequestPasswordResetForm from '../RequestPasswordResetForm';
import PasswordResetForm from '../PasswordResetForm';
import GetApp from '../LogIn/components/GetApp';
import Information from '../Information';
import withFirstLogin from '../../hoc/withFirstLogin';
import getDevice from '../../utils/device';
import { getLanguage } from '../../utils/language';
import '../../styles/App.scss';

// load locales and translations to be used accross the app
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import messages from '../../messages';

// merge all locales into one array and apply them
addLocaleData([...de, ...en]);

const language = getLanguage();

export class App extends Component {
  applyAllHocs = component => {
    return withFirstLogin(
      withAuth({ isLoggedIn: this.props.isLoggedIn })(component)
    );
  };

  render() {
    const lang = this.props.lang || language;
    const { isLoggedIn } = this.props;
    const localeData = messages[lang.split(/[-_]/)[0]];

    return (
      <IntlProvider locale={lang} messages={flattenMessages(localeData)}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME || '/'}>
          <>
            {!isLoggedIn && getDevice() && <GetApp />}

            <Header />
            <Switch>
              <Route path="/login" component={LogIn} />
              <Route
                path="/reset-password"
                component={RequestPasswordResetForm}
              />
              <Route
                path="/set-new-password/:uid/:timestamp/:hash"
                component={PasswordResetForm}
              />
              <Route
                path="/logout"
                component={withAuth({ isLoggedIn })(LogOut)}
              />

              <Route path="/imprint" component={Information} />
              <Route path="/terms_and_conditions" component={Information} />
              <Route path="/privacy_policy" component={Information} />
              <Route
                path="/set-password"
                component={withAuth({ isLoggedIn })(
                  FirstLoginPasswordChangePrompt
                )}
              />
              <Route
                path="/set-email"
                component={withAuth({ isLoggedIn })(FirstLoginEmailSetPrompt)}
              />
              <Route path="/" component={this.applyAllHocs(Main)} />
            </Switch>
          </>
        </BrowserRouter>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  lang: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    lang: state.lang.lang,
    isLoggedIn: state.auth.isLoggedIn
  };
};

export default connect(mapStateToProps)(App);
