import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink, Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import PasswordForm from '../../components/PasswordForm';
import Footer from '../../components/Footer';
import RightSidebar from '../../components/RightSidebar';
import settings from '../../utils/settings';
let headerLogos = require('../../images/' + settings.getSetting('site_logo'));

export class FirstLoginPasswordChangePrompt extends Component {
  render() {
    const { user } = this.props;
    const needsPasswordChange =
      user.attributes && user.attributes.field_needs_password_change;

    if (!needsPasswordChange) {
      return <Redirect to="/set-email" />;
    }

    return (
      <Container
        fluid
        className="first-login-prompt text-center container-fluid"
      >
        <Row>
          <Col
            lg="10"
            className="d-flex flex-column justify-content-between align-items-center"
          >
            <div className="set-password d-flex flex-column flex-fill h-100 justify-content-center align-items-center text-center">
              <PasswordForm isFirstTime>
                <>
                  <img
                    alt={settings.getSetting('site_name')}
                    className="logo my-2"
                    src={headerLogos}
                  />
                  <p>
                    <FormattedMessage id="logIn.changePasswordAfterFirstLogin" />
                  </p>
                </>
              </PasswordForm>
              <p>
                <FormattedMessage id="logIn.or" />
              </p>
              <NavLink to="/logout">
                <FormattedMessage id="logIn.logOut" />
              </NavLink>
            </div>

            <Footer />
          </Col>

          <RightSidebar />
        </Row>
      </Container>
    );
  }
}

FirstLoginPasswordChangePrompt.propTypes = {
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user.data
  };
}

export default connect(mapStateToProps)(FirstLoginPasswordChangePrompt);
