import React, { Component } from 'react';
import { Button, Container } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { apiService } from '../../services';
import ActionsList from '../../components/ActionsList';
import Toast from '../../components/Toast';
import { FormEdit, FormTrash } from 'grommet-icons';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Legend } from '../../components/Legend';

export class Favorites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showActions: false,
      showError: false,
      favoritesLoaded: false
    };
  }

  get actions() {
    return [
      {
        label: this.props.intl.formatMessage({
          id: 'favorites.actions.remove.label'
        }),
        icon: <FormTrash />,
        callback: this.removeFavorite
      }
    ];
  }

  componentDidMount() {
    const { getFavorites, user } = this.props;

    getFavorites(user.id).then(() => {
      this.setState({
        favoritesLoaded: true
      });
    });
  }

  generateLink = favorite => {
    return favorite.attributes.value_type === 'gene'
      ? `/my-dna/genes/${favorite.attributes.name}`
      : `/search/results?searchParam=${favorite.attributes.value_value.value}&searchLabel=${favorite.attributes.name}&searchType=${favorite.attributes.value_type}`;
  };

  removeFavorite = async (favoriteId, component) => {
    const { unmarkAsFavorite, user } = this.props;
    const componentEl = component.current;

    componentEl.classList.add('isRemoving');

    const onError = () => {
      componentEl.classList.remove('isRemoving');
      this.onError();
    };

    unmarkAsFavorite(favoriteId, user, onError);
  };

  toggleActions = () => {
    this.setState({
      showActions: !this.state.showActions
    });
  };

  onError = () => {
    this.setState({
      showError: true
    });
  };

  closeError = () => {
    if (this.state.showError) {
      this.setState({
        showError: false
      });
    }
  };

  render() {
    const favoritesItems = this.props.favorites.included || [];
    const sortedFavorites = {
      drugs: [],
      genes: []
    };

    favoritesItems.forEach(favorite => {
      const favoriteItem = {
        id: favorite.id,
        label: favorite.attributes.name,
        type: favorite.attributes.value_type === 'gene' ? 'genes' : 'drugs',
        link: this.generateLink(favorite)
      };

      if (favorite.attributes.value_type === 'active_ingredient_name') {
        favoriteItem.recommendation_status =
          favorite.attributes.value_value.recommendation_status;
      }

      sortedFavorites[favoriteItem.type].push(favoriteItem);
    });

    const showLoadingStatus =
      this.props.isFetching && !this.state.favoritesLoaded;

    return showLoadingStatus ? (
      <LoadingSpinner messageId="favorites.loadingMessage" />
    ) : (
      <Container className="favorites p-5">
        <Toast isOpen={this.state.showError} onClose={this.closeError}>
          <FormattedMessage id="favorites.actions.remove.errorMessage" />
        </Toast>
        <h1 className="mb-4">
          <FormattedMessage id="navbar.favorites" />
        </h1>
        {favoritesItems.length > 0 ? (
          <>
            <p>
              <Button
                outline
                color="secondary"
                size="sm"
                className="show-actions"
                onClick={this.toggleActions}
              >
                <FormEdit />{' '}
                <FormattedMessage
                  id={`favorites.${
                    this.state.showActions ? 'finishButton' : 'editButton'
                  }`}
                />
              </Button>
            </p>
            {Object.keys(sortedFavorites).map(key => {
              const favoritesGroup = sortedFavorites[key];

              return (
                <div className="my-4" key={key}>
                  {favoritesGroup.length > 0 && (
                    <>
                      <h3>
                        <FormattedMessage id={`favorites.${key}`} />
                      </h3>
                      <ActionsList
                        listItems={favoritesGroup}
                        actions={this.actions}
                        showActions={this.state.showActions}
                      />
                    </>
                  )}
                </div>
              );
            })}
            <Legend />
          </>
        ) : (
          <FormattedMessage id="favorites.empty" />
        )}
      </Container>
    );
  }
}

Favorites.propTypes = {
  getFavorites: PropTypes.func.isRequired,
  unmarkAsFavorite: PropTypes.func.isRequired,
  favorites: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  intl: intlShape.isRequired
};

const mapStateToProps = state => {
  return {
    favorites: state.favorites.data,
    user: state.user.data,
    isFetching: state.favorites.isFetching
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    {
      getFavorites: apiService.getFavorites,
      unmarkAsFavorite: apiService.unmarkAsFavorite
    }
  )(Favorites)
);
