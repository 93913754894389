import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './configureStore';
import Root from './Root';
import { requestNotificationPermission } from './utils/notifications';
import settings from './utils/settings';

const store = configureStore();

// ask for notification permissions
requestNotificationPermission();

document.title = settings.getSetting('site_title');
let link =
  document.querySelector("link[rel*='icon']") || document.createElement('link');
link.type = 'image/x-icon';
link.rel = 'shortcut icon';
link.href = process.env.PUBLIC_URL + '/' + settings.getSetting('favicon');
document.getElementsByTagName('head')[0].appendChild(link);

ReactDOM.render(<Root store={store} />, document.getElementById('root'));

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
