import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  FormGroup,
  Label
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { apiService } from '../../services';

export class LangForm extends Component {
  handleChange = evt => {
    this.props.setLang(this.props.userId, evt.target.value);
  };

  render() {
    const { error, lang } = this.props;

    return (
      <Form>
        <Card className="lang-form">
          <CardHeader>
            <FormattedMessage id="languageForm.header" />
          </CardHeader>
          <CardBody>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="radio"
                  value="de"
                  checked={lang === 'de'}
                  onChange={this.handleChange}
                />
                <FormattedMessage id="languageForm.de" />
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="radio"
                  value="en"
                  checked={lang === 'en'}
                  onChange={this.handleChange}
                />
                <FormattedMessage id="languageForm.en" />
              </Label>
            </FormGroup>
            {error && (
              <p className="error">
                <FormattedMessage id="languageForm.error" />
              </p>
            )}
          </CardBody>
        </Card>
      </Form>
    );
  }
}

LangForm.propTypes = {
  lang: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  setLang: PropTypes.func,
  error: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    lang: state.lang.lang,
    userId: state.user.data.id
  };
};

export default connect(
  mapStateToProps,
  {
    setLang: apiService.setLang
  }
)(LangForm);
