import { combineReducers } from 'redux';
import {
  authReducer as auth,
  langReducer as lang,
  clinicalReportReducer as clinicalReport,
  customerReducer as customer,
  printJobReducer as printJob,
  userReducer as user,
  actionStackReducer as actionStack,
  searchReducer as search,
  autocompleteReducer as autocomplete,
  geneInformationReducer as geneInformation,
  tablesReducer as tables,
  staticContentReducer as staticContent,
  fileReducer as file,
  favoritesReducer as favorites,
  CLEAR_STATE
} from 'client-core';

const appReducer = combineReducers({
  actionStack,
  auth,
  clinicalReport,
  customer,
  lang,
  autocomplete,
  search,
  user,
  geneInformation,
  printJob,
  staticContent,
  tables,
  file,
  favorites
});

// wrapp combined reducers, this allows us to manipulate
// the global state when specific actions get fired
const rootReducer = (state, action) => {
  // catch logout action and clear global state
  if (action.type === CLEAR_STATE) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
