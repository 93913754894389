import React, { Component } from 'react';
import { Modal } from 'biologis-react-components';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import { apiService } from '../../services/index';
import { createIssue } from '../../api/service-desk';

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: '',
      likeText: '',
      improveText: '',
      formSent: false,
      errorMessageId: '',
      isLoading: false
    };
  }

  resetForm = () => {
    this.setState({
      rating: '',
      likeText: '',
      improveText: '',
      formSent: false,
      errorMessageId: '',
      isLoading: false
    });
  };

  handleChange = ev => {
    this.setState({
      [ev.target.name]: ev.target.value
    });
  };

  handleError = (messageId, log = null) => {
    if (log) {
      console.log(log);
    }
    this.setState({
      formSent: false,
      errorMessageId: messageId
    });
    this.toggleLoading();
  };

  handleSuccess = () => {
    this.resetForm();
    this.setState({
      formSent: true,
      errorMessageId: ''
    });
    this.toggleLoading();
  };

  toggleLoading = () => {
    this.setState(state => {
      return {
        isLoading: !state.isLoading
      };
    });
  };

  handleSubmit = () => {
    this.toggleLoading();
    if (this.state.rating.length <= 0) {
      this.handleError('requiredFields');
    } else {
      createIssue(this.state)
        .then(res => {
          if (res.ok) {
            this.handleSuccess();
          } else {
            this.handleError('requestError', res.statusText);
          }
        })
        .catch(error => {
          this.handleError('requestError', error);
        });
    }
  };

  render() {
    const { intl } = this.props;
    const {
      errorMessageId,
      isLoading,
      improveText,
      likeText,
      rating
    } = this.state;

    const RatingInput = ({ value, labelMessageId }) => (
      <FormGroup className="pr-2" check>
        <Label check>
          <Input
            type="radio"
            name="rating"
            value={value}
            onChange={this.handleChange}
            checked={rating === value}
          />
          <FormattedMessage id={labelMessageId} />
        </Label>
      </FormGroup>
    );

    return (
      <>
        <h1>
          <FormattedMessage id="feedback.title" />
        </h1>
        <Modal
          buttonColor="primary"
          buttonLabel={intl.formatMessage({
            id: 'feedback.openModalButton'
          })}
          size="lg"
          title={intl.formatMessage({ id: 'feedback.title' })}
          withFooter={false}
          onClosed={this.resetForm}
          render={toggleModal =>
            this.state.formSent ? (
              <>
                <p>
                  <FormattedMessage id="feedback.statusSuccess" />
                </p>
                <Button
                  color="primary"
                  onClick={toggleModal}
                  className="float-right"
                >
                  <FormattedMessage id="feedback.cancelButton" />
                </Button>
              </>
            ) : (
              <>
                {errorMessageId && (
                  <Alert color="danger">
                    <FormattedMessage
                      id={`feedback.statusError.${errorMessageId}`}
                    />
                  </Alert>
                )}
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    this.handleSubmit();
                  }}
                >
                  <FormGroup row>
                    <Label sm={12}>
                      <FormattedMessage id="feedback.rateThisPage" />
                      <span className="required">*</span>
                    </Label>
                    <Col sm={12} className="d-flex flex-row">
                      <RatingInput
                        value="Awesome!"
                        labelMessageId="feedback.rating.awesome"
                      />
                      <RatingInput
                        value="Good"
                        labelMessageId="feedback.rating.good"
                      />
                      <RatingInput
                        value="Meh!"
                        labelMessageId="feedback.rating.meh"
                      />
                      <RatingInput
                        value="Bad"
                        labelMessageId="feedback.rating.bad"
                      />
                      <RatingInput
                        value="Horrible!"
                        labelMessageId="feedback.rating.horrible"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="like" sm={12}>
                      <FormattedMessage id="feedback.whatDoYouLike" />
                    </Label>
                    <Col sm={12}>
                      <Input
                        type="textarea"
                        name="likeText"
                        id="like"
                        onChange={this.handleChange}
                        value={likeText}
                        style={{ height: 140 }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="improve" sm={12}>
                      <FormattedMessage id="feedback.whatNeedsToBeImproved" />
                    </Label>
                    <Col sm={12}>
                      <Input
                        type="textarea"
                        name="improveText"
                        id="improve"
                        onChange={this.handleChange}
                        value={improveText}
                        style={{ height: 140 }}
                      />
                    </Col>
                  </FormGroup>
                  <p>
                    <span className="required">*</span>{' '}
                    <FormattedMessage id="feedback.requiredField" />
                  </p>
                  <Button
                    type="submit"
                    color="primary"
                    className="float-right"
                    disabled={isLoading}
                  >
                    {isLoading && <Spinner size="sm" color="light" />}{' '}
                    <FormattedMessage id="feedback.submitButton" />
                  </Button>
                </Form>
              </>
            )
          }
        />
      </>
    );
  }
}

Feedback.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(
  connect(
    null,
    { createIssue: apiService.createIssue }
  )(Feedback)
);
