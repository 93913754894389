import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Container, Card, CardBody } from 'reactstrap';
import rbk from '../../images/rbk.png';
import PrintJob from './components/PrintJob';
import { apiService } from '../../services';
import settings from "../../utils/settings";

export class Report extends Component {
  constructor(props) {
    super(props);
    this.reportContainer = null;
    this.setReportContainer = this.setReportContainer.bind(this);
  }

  componentDidMount() {
    const { lang, getClinicalReports } = this.props;
    getClinicalReports(
      `/static_clinical_report?content_langcode=${lang}&filter[langcode]=${lang}`
    );
  }

  setReportContainer(e) {
    this.reportContainer = e;
  }

  render() {
    const {
      createPrintJob,
      getPrintJob,
      downloadFile,
      clinicalReports,
      printJob,
      lang,
      tokens
    } = this.props;
    const firstClinicalReport = clinicalReports[0] || undefined;

    return (
      <Container className="ml-0 p-5">
        <h1 className="mb-4">
          <FormattedMessage id="navbar.report" />
        </h1>
        <div className="header-toolbar">
          <Card className="email-form">
            <CardBody>
              <p>
                <FormattedHTMLMessage id="report.reportInfo" />
              </p>
              {firstClinicalReport && (
                <PrintJob
                  createPrintJob={createPrintJob}
                  getPrintJob={getPrintJob}
                  downloadFile={downloadFile}
                  printJob={printJob}
                  clinicalReport={firstClinicalReport}
                  lang={lang}
                  accessToken={tokens.access_token}
                />
              )}
            </CardBody>
          </Card>
        </div>
        <div className="full-report p-4" ref={this.setReportContainer}>
          <div className="header">
            <img
              className="logo"
              alt={settings.getSetting('site_name')}
              src={rbk}
            />
            <div className="company-name">
              <span>
                Zentrum für Diagnostische Medizin des Robert-Bosch-Krankenhauses
              </span>
              <span className="sub">
                Dr. Margarete Fischer-Bosch-Institut für Klinische Pharmakologie
                (IKP)
              </span>
            </div>
          </div>
          {firstClinicalReport ? (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  firstClinicalReport.attributes.field_report_text.processed
              }}
            />
          ) : (
            <FormattedHTMLMessage id="report.noReportFound" />
          )}
          <div className="report-footer">
            <div>
              <span>
                Dr. Margarete Fischer-Bosch-Institut für Klinische Pharmakologie
              </span>
              <span>Auerbachstraße 112, 70376 Stuttgart</span>
              <span>Telefon +49(0)711/8101-3700</span>
              <a href="www.ikp-stuttgart.de">www.ikp-stuttgart.de</a>
              <span>Eine Einrichtung der Robert Bosch Stiftung</span>
            </div>
            <div>
              <span>Robert-Bosch-Krankenhaus</span>
              <span>Auerbachstraße 110</span>
              <span>70376 Stuttgart</span>
              <span>Telefon +49(0)711/8101-0</span>
              <span>Telefax +49(0)711/8101-3790</span>
              <a href="www.rbk.de">www.rbk.de</a>
            </div>
            <div>
              <span>Akademisches Lehrkrankenhaus der Universität Tübingen</span>
              <span>Bankverbindung:</span>
              <span>Baden-Württembergische Bank</span>
              <span>IBAN DE83600501010002152059</span>
              <span>BIC SOLADEST600</span>
            </div>
            <div>
              <span>Träger: Robert-Bosch-Krankenhaus GmbH</span>
              <span>Sitz: Stuttgart, Amtsgericht Stuttgart HRB 41</span>
              <span>Geschäftsführer:</span>
              <span>Ullrich Hipp (Sprecher der Geschäftsführung),</span>
              <span>Prof. Dr. Mark Dominik Alscher</span>
              <span>Eine Einrichtung der Robert Bosch Stiftung</span>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

Report.propTypes = {
  getClinicalReports: PropTypes.func.isRequired,
  createPrintJob: PropTypes.func.isRequired,
  getPrintJob: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  clinicalReports: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
  contentVariables: PropTypes.string,
  printJob: PropTypes.object,
  tokens: PropTypes.object
};

const mapStateToProps = state => {
  return {
    clinicalReports: state.clinicalReport.data,
    lang: state.lang.lang,
    printJob: state.printJob,
    tokens: state.auth.tokens
  };
};

export default connect(
  mapStateToProps,
  {
    getClinicalReports: apiService.getClinicalReports,
    createPrintJob: apiService.createPrintJob,
    getPrintJob: apiService.getPrintJob,
    downloadFile: apiService.downloadFile
  }
)(Report);
