import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoadingSpinner from '../LoadingSpinner';

class Overlay extends Component {
  constructor(props) {
    super(props);

    this.messageId = props.messageId || 'app.loading';
    this.restrictToPath = props.restrictToPath || '/';
  }
  render() {
    const { isFetching, location } = this.props;
    const showOverlay =
      isFetching === true && location.pathname === this.restrictToPath;

    return (
      <div
        className="loading-overlay"
        style={{ display: showOverlay ? 'block' : 'none' }}
      >
        <LoadingSpinner messageId={this.messageId} />
      </div>
    );
  }
}

Overlay.propTypes = {
  restrictToPath: PropTypes.string.isRequired,
  messageId: PropTypes.string,
  isFetching: PropTypes.bool,
  location: PropTypes.object
};

const mapStateToProps = state => {
  return {
    isFetching: state.user.isFetching || state.auth.isFetching
  };
};

export default withRouter(connect(mapStateToProps)(Overlay));
