import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Footer from '../../components/Footer';
import RightSidebar from '../../components/RightSidebar';
import EmailForm from '../../components/EmailForm';
import settings from '../../utils/settings';
let headerLogos = require('../../images/' + settings.getSetting('site_logo'));

export class FirstLoginEmailSetPrompt extends Component {
  render() {
    const { user } = this.props;
    const needsEmailDialog =
      user.attributes && user.attributes.field_needs_email_dialog;

    if (!needsEmailDialog) {
      return <Redirect to="/" />;
    }

    return (
      <Container
        fluid
        className="first-login-prompt text-center container-fluid"
      >
        <Row>
          <Col
            lg="10"
            className="d-flex flex-column justify-content-between align-items-center"
          >
            <div className="set-password d-flex flex-column flex-fill h-100 justify-content-center align-items-center text-center">
              <EmailForm isFirstTime>
                <img
                  alt={settings.getSetting('site_name')}
                  className="logo my-2"
                  src={headerLogos}
                />
              </EmailForm>
            </div>
            <p>
              <FormattedMessage id="logIn.or" />
            </p>
            <NavLink to="/logout">
              <FormattedMessage id="logIn.logOut" />
            </NavLink>

            <Footer />
          </Col>

          <RightSidebar />
        </Row>
      </Container>
    );
  }
}

FirstLoginEmailSetPrompt.propTypes = {
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user.data
  };
}

export default connect(mapStateToProps)(FirstLoginEmailSetPrompt);
