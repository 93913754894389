import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

export default WrappedComponent => {
  class FirstLogin extends Component {
    render() {
      if (
        this.props.user.attributes &&
        this.props.user.attributes.field_needs_password_change === true
      ) {
        return <Redirect to="/set-password" />;
      } else if (
        this.props.user.attributes &&
        this.props.user.attributes.field_needs_email_dialog === true
      ) {
        return <Redirect to="/set-email" />;
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  FirstLogin.propTypes = {
    user: PropTypes.object
  };

  function mapStateToProps(state) {
    return {
      user: state.user.data
    };
  }

  return connect(mapStateToProps)(FirstLogin);
};
