import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { apiService } from '../../services';
import { Button, Spinner } from 'reactstrap';
import { Star, StarOutline } from '../Icons';
import { checkIsMarkedAsFavorite, getFavoriteId } from 'client-core';
import Toast from '../../components/Toast';

export class FavoriteStar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMarked: false,
      isLoading: false,
      showError: false
    };
  }

  // check if the favorite data like the list id are available, if not get them
  componentDidMount() {
    const { favorite, favorites, user, getFavorites } = this.props;

    if (!favorites || !favorites.data) {
      getFavorites(user.id).then(() => {
        this.setState({
          isMarked: checkIsMarkedAsFavorite(favorite.name, favorites)
        });
      });
    } else {
      this.setState({
        isMarked: checkIsMarkedAsFavorite(favorite.name, favorites)
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isMarked !== this.state.isMarked) {
      this.setState({
        isLoading: false
      });
    }
  }

  onError = () => this.setState({ showError: true });

  handleResponse = () => {
    this.setState(prevState => ({
      isMarked: prevState.showError ? prevState.isMarked : !prevState.isMarked,
      isLoading: false
    }));
  };

  handleClick = () => {
    const { favorite, favorites, user } = this.props;

    this.setState(
      {
        isLoading: true
      },
      () => {
        if (this.state.isMarked) {
          this.props
            .unmarkAsFavorite(
              getFavoriteId(favorite.name, favorites),
              user,
              this.onError
            )
            .then(this.handleResponse);
        } else {
          this.props
            .markAsFavorite(favorite, favorites, user, this.onError)
            .then(this.handleResponse);
        }
      }
    );
  };

  render() {
    const { labelAdd, labelRemove, labelError } = this.props;
    const { isMarked, isLoading } = this.state;

    return (
      <>
        <Toast
          isOpen={this.state.showError}
          onClose={() => this.setState({ showError: false })}
        >
          {labelError}
        </Toast>
        <Button className="favorite-button" onClick={this.handleClick}>
          {isLoading ? (
            <Spinner size="sm" color="primary" type="grow" />
          ) : isMarked ? (
            <Star />
          ) : (
            <StarOutline className="stroke" />
          )}{' '}
          {isMarked ? labelRemove : labelAdd}
        </Button>
      </>
    );
  }
}

FavoriteStar.propTypes = {
  user: PropTypes.object.isRequired,
  getFavorites: PropTypes.func.isRequired,
  markAsFavorite: PropTypes.func.isRequired,
  unmarkAsFavorite: PropTypes.func.isRequired,
  favorites: PropTypes.object.isRequired,
  favorite: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.shape({
      value: PropTypes.string.isRequired,
      recommendation_status: PropTypes.string
    })
  }),
  labelAdd: PropTypes.string,
  labelRemove: PropTypes.string,
  labelError: PropTypes.string
};

const mapStateToProps = state => {
  return {
    user: state.user.data,
    favorites: state.favorites.data
  };
};

export default connect(
  mapStateToProps,
  {
    getFavorites: apiService.getFavorites,
    markAsFavorite: apiService.markAsFavorite,
    unmarkAsFavorite: apiService.unmarkAsFavorite
  }
)(FavoriteStar);
