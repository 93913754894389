import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { apiService } from '../../services';

class LogOut extends Component {
  componentDidMount() {
    this.props.logOut();
  }

  render() {
    return <Redirect to="/login" />;
  }
}

LogOut.propTypes = {
  logOut: PropTypes.func
};

export default connect(
  null,
  { logOut: apiService.logOut }
)(LogOut);
