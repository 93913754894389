import React, { Component, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Nav, NavItem, Row, Collapse } from 'reactstrap';
import { Logout, CircleQuestion, Menu } from 'grommet-icons';
import classNames from 'classnames';
import { MedCheck, MyDna, Settings, Star } from '../../../../components/Icons';
import TutorialModal from '../../../../components/TutorialModal';
import settings from '../../../../utils/settings';

export class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavOpen: false
    };
  }

  toggleNav = () => {
    this.setState(prevState => ({
      isNavOpen: !prevState.isNavOpen
    }));
  };

  getLogo = () => {
    const { file } = this.props;
    let logo;

    if (file && file.attributes) {
      logo = settings.getSetting('server_root') + '' + file.attributes.uri.url;
    } else {
      logo = require(`../../../../images/` + settings.getSetting('site_logo'));
    }

    return logo;
  };

  render() {
    const { user, location } = this.props;
    const { isNavOpen } = this.state;

    const navbarClasses = classNames('sidebar-bottom d-lg-flex flex-column', {
      'd-none': !isNavOpen
    });

    const NavElement = props => {
      const { to, icon, messageId, ...rest } = props;
      const [isActive] = useState(location.pathname.startsWith(to));

      return (
        <NavItem active={isActive}>
          <NavLink className="label" to={to} {...rest}>
            <Row className="no-gutters">
              {icon && <Col xs="2">{icon}</Col>}
              <Col xs={icon ? '10' : '12'}>
                <FormattedMessage id={messageId} />
              </Col>
            </Row>
          </NavLink>
        </NavItem>
      );
    };

    const NavDropdown = props => {
      const { children, icon, messageId, ...rest } = props;
      const togglerId = messageId.replace(/\W/g, ''); // \W is the equivalent of [^0-9a-zA-Z_]
      const isChildActive =
        React.Children.map(
          children,
          child => child.props.to.split('/')[1]
        ).indexOf(location.pathname.split('/')[1]) >= 0; // check if any of the children elements is active
      const [isActive] = useState(isChildActive); // see react hooks
      const [isOpen, updateIsOpen] = useState(isActive); // see react hooks

      return (
        <NavItem active={isActive}>
          <span
            className="toggler label"
            onClick={() => updateIsOpen(!isOpen)}
            id={togglerId}
            {...rest}
          >
            <Row className="no-gutters">
              {icon && <Col xs="2">{icon}</Col>}
              <Col xs={{ size: '10', offset: icon ? '0' : '2' }}>
                <FormattedMessage id={messageId} />
              </Col>
            </Row>
          </span>
          <Collapse isOpen={isOpen}>
            <Row className="no-gutters">
              <Col xs={{ size: '10', offset: '2' }}>
                <ul className="sub-nav" xs={{ size: '10', offset: '2' }}>
                  {children}
                </ul>
              </Col>
            </Row>
          </Collapse>
        </NavItem>
      );
    };

    return (
      <div className="sidebar-sticky d-flex flex-column">
        <div className="sidebar-top">
          <div className="px-3">
            <Row>
              <Col xs="10" lg="12">
                <img
                  alt={settings.getSetting('site_name')}
                  className="img-fluid mx-auto d-block logo p-3"
                  src={this.getLogo()}
                />
              </Col>
              <Col
                xs="2"
                className="d-lg-none justify-content-center align-items-center"
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleNav}
                >
                  <Menu />
                </button>
              </Col>
            </Row>
          </div>
        </div>
        <div className={navbarClasses}>
          <div className="user-menu">
            <Row noGutters>
              <Col xs="12" xl="8">
                <div className="user-info">
                  <p>
                    <FormattedMessage id="navbar.userInfo" />
                    <br />
                    <span className="username">{user.attributes.name}</span>
                  </p>
                </div>
              </Col>
              <Col xs="12" xl="4">
                <div className="user-actions">
                  <NavLink className="nav-logout" to="/logout">
                    <div className="logout">
                      <div className="icon">
                        <Logout className="stroke" />
                      </div>
                      <div className="label">
                        <FormattedMessage id="navbar.logout" />
                      </div>
                    </div>
                  </NavLink>
                </div>
              </Col>
            </Row>
          </div>
          <div className="main-menu">
            <Nav vertical>
              <NavElement
                exact
                to="/search"
                icon={<MedCheck />}
                messageId="navbar.check"
              />

              <NavDropdown icon={<MyDna />} messageId="navbar.mydna">
                <NavElement to="/my-dna/reports" messageId="navbar.report" />
                <NavElement to="/my-dna/drugs" messageId="navbar.drugs" />
                <NavElement to="/my-dna/genes" messageId="navbar.genes" />
                <NavElement to="/my-dna/results" messageId="navbar.results" />
              </NavDropdown>

              <NavElement
                to="/favorites"
                icon={<Star />}
                messageId="navbar.favorites"
              />

              <NavItem>
                <TutorialModal />
              </NavItem>

              <NavElement
                to="/info/support_info"
                icon={<CircleQuestion className="stroke" />}
                messageId="navbar.help"
              />

              <NavElement
                to="/settings"
                icon={<Settings />}
                messageId="navbar.settings"
              />

              <NavItem className="divider" />

              <NavElement
                to="/info/imprint"
                messageId="footer.imprint"
                className="label"
              />

              <NavElement
                to="/info/terms_and_conditions"
                messageId="footer.terms"
                className="label"
              />

              <NavElement
                to="/info/privacy_policy"
                messageId="footer.policy"
                className="label"
              />
            </Nav>
          </div>
        </div>
      </div>
    );
  }
}

Navbar.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object,
  openOnFirstLoad: PropTypes.bool,
  file: PropTypes.object
};

const mapStateToProps = state => {
  return {
    user: state.user.data,
    file: state.file.data
  };
};

export default withRouter(connect(mapStateToProps)(Navbar));
